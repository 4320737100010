import { ChakraProvider } from '@chakra-ui/react';
import Head from 'next/head';
import AuthProvider from 'contexts/AuthContext';
import { theme } from 'styles/theme';
import 'styles/globals.scss';
import FormProvider, {
  defaultValues,
  IFormUser,
  userSchema,
} from 'contexts/FormContext';
import { useForm, FormProvider as HookFormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActiveUserProvider from 'contexts/ActiveUserContext';
import FilesProvider from 'contexts/FilesContext';
import type { AppProps } from 'next/app';

function MyApp({ Component, pageProps }: AppProps) {
  const formMethods = useForm<IFormUser>({
    resolver: yupResolver(userSchema),
    defaultValues,
  });

  return (
    <ChakraProvider resetCSS theme={theme}>
      <AuthProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <ActiveUserProvider>
          <FilesProvider>
            <HookFormProvider {...formMethods}>
              <FormProvider>
                <Component {...pageProps} />
              </FormProvider>
            </HookFormProvider>
          </FilesProvider>
        </ActiveUserProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default MyApp;
