import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  getAuth,
  signOut as signOutGoogle,
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { firebaseApp } from 'services/firebase';
import { useRouter } from 'next/router';
import { setCookie, destroyCookie } from 'nookies';

export interface IGoogleUser {
  id: string;
  avatar?: string;
  displayName: string;
  email: string;
  phoneNumber?: string;
}

export interface IUserCredential extends UserCredential {
  _tokenResponse: {
    oauthAccessToken: string;
  };
}

export const AuthContext = createContext<{
  user: IGoogleUser | undefined;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
}>({
  user: undefined,
  signInWithGoogle: () => new Promise(() => null),
  signOut: () => new Promise(() => null),
});

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<IGoogleUser>();
  const auth = getAuth(firebaseApp);
  const router = useRouter();

  useEffect(() => {
    const authUnsubscriber = auth.onAuthStateChanged(user => {
      if (user?.displayName && user?.email) {
        setUser({
          id: user.uid,
          avatar: user.photoURL ?? '',
          displayName: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber ?? '',
        });
      }
    });

    return () => {
      authUnsubscriber();
    };
  }, [auth]);

  const signInWithGoogle = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/calendar.readonly');
      provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      provider.addScope('https://www.googleapis.com/auth/directory.readonly');

      const result = await signInWithPopup(auth, provider);
      if ((result as IUserCredential)?.['_tokenResponse'].oauthAccessToken) {
        setCookie(
          null,
          'FortinisOAToken',
          (result as IUserCredential)['_tokenResponse'].oauthAccessToken,
          {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [auth]);

  const signOut = useCallback(async () => {
    signOutGoogle(auth)
      .then(() => {
        destroyCookie(null, 'FortinisOAToken');
        setUser(undefined);
        router.push('/');
      })
      .catch(error => {
        console.log(error);
      });
  }, [auth, router]);

  return (
    <AuthContext.Provider value={{ user, signInWithGoogle, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}
